.card {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 1rem;
}

.card {
  margin-top: 10px;
  box-sizing: border-box;
  background-clip: padding-box;
    border: none;
}
.card span.card-title {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
}

.card .card-image {
  position: relative;
  overflow: hidden;
}
.card .card-image img {
  border-radius: 1rem 1rem 0 0;
  background-clip: padding-box;
  position: relative;
  z-index: -1;
}
.card .card-image span.card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
}
.card .card-content {
  padding: 16px;
  border-radius: 0 0 2px 2px;
  background-clip: padding-box;
  box-sizing: border-box;
}
.card .card-content p {
  margin: 0;
  color: inherit;
}
.card .card-content span.card-title {
  line-height: 48px;
}
.card .card-action {
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  padding: 16px;
}
.card .card-action a {
  color: #ffab40;
  margin-right: 16px;
  transition: color 0.3s ease;
  text-transform: uppercase;
}
.card .card-action a:hover {
  color: #ffd8a6;
  text-decoration: none;
}

.card.card-table {
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);

	.card-header {
	    position: relative;
	    display: block;
	    padding: 26px 30px;
	    border-radius: 1rem 1rem 0px 0px;
		background-color: rgb(247, 247, 247);

		&.shrink {
			position: fixed;
			top: 0;
			z-index: 10;
		}

		h2 {
		    line-height: 100%;
		    font-size: 16px;
		    font-weight: 700;
		    margin: 0px;
            color: #3c4b64;

			small {
			    display: block;
			    margin-top: 8px;
			    //color: rgb(174, 174, 174);
			    line-height: 160%;
			}
		}

		> .btn-float {
			right: 25px;
		}

		.btn-action {
		    right: 25px;
		    bottom: -23px;
		    z-index: 1;
			position: absolute;

			> .btn-float {
				position: relative;

				i {
					img {
						display: inline-block;
						margin-top: -10px;
					}
				}

				+ * {
					margin-left: 5px;
				}
			}
		}
	}

	.card-body {
	    padding: 26px 30px;
	}

	.card-header + .card-body {
		padding-top: 56px;
	}
}
