.header.header {
	.header-menu {
		list-style: none;
		margin: 0;
		padding: 15px 0;
		> li {
			> a {
				color: $color-white;
			}

			&:not(.pull-right) {
				float: left;
			}

			&:first-child {
				width: 30px;
			}
		}

		.header-menu-logo {
		    text-transform: uppercase;
		    font-size: 20px;
			padding: 5px 10px 5px 0;
		}

		> li > .header-menu {
			padding-top: 5px;
			> li > a > i {
				font-size: 30px;
			}
		}
	}
}
