@import "header/navigation";
@import "header/header-menu";

header.header {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    min-height: 70px;
    user-select: none;
    position: fixed;
    z-index: 11;
    width: 100%;
    left: $slidebar-with ;
    top: 0;
    padding: 0 20px;
	background: #92051e;
    width: calc(100% - #{$slidebar-with});

    @media(max-width: 1199px) {
        left: 0;
        width: 100%;
    }
}

