.mobile-slidebar {
    background-color: $bg-slidebar;
    width: 300px;
    overflow: hidden;
    //overflow-y: auto; /* has to be scroll, not auto */
    //-webkit-overflow-scrolling: touch;

    .mobile-slidebar-container  {
        height:100%;
        overflow:auto;
        -webkit-overflow-scrolling:touch;

        &::-webkit-scrollbar-track
        {
        	-webkit-box-shadow: none;
        	background-color: transparent;
        }

        &::-webkit-scrollbar
        {
            width: 4px;
        	height: 4px;
            //right: 20px;
        	background-color: transparent;
            position: absolute !important;
        }

        &::-webkit-scrollbar-thumb
        {
        	background-color: $bg-slidebar;
            border-radius: 100px;
        }
    }

    .mobile-slidebar-block {
        padding: 0 20px;

        .mobile-slidebar-block-title {
            font-weight: 400;
            margin-bottom: 20px;
        }

        .mobile-slidebar-social {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
    }

    .mobile-slidebar-header {
        min-height: 150px;
        /*background-image: url('../images/bg-slidebar.png');*/
        position: relative;
		background: #fff;

    	img {
			height: 60px;
    		margin: 0 auto;
    	}

        .mobile-slidebar-title {
            position: absolute;
            background-color: darken($color-primary, 10%);
            display: block;
            bottom: 0;
            width: 100%;
            vertical-align: middle;
            //padding: 10px 20px;
            height: 50px;
            overflow: hidden;

            .mobile-slidebar-t {
                //display: inline-block;
                float: right;
                margin-right: 20px;
                line-height: 50px;
				height: 50px;
    			overflow: hidden;

    			.fa-close {
					font-size: 18px;
    				transition: all ease 0.3s;
    				cursor: pointer;

			    	&:hover {
	    				transform: rotate(180deg);
			    	}
    			}
            }

            h3 {
                color: #fff;
                font-size: 16px;
                margin: 0;
                word-spacing: -4px;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;

                span {
                    font-weight: 400;
                }
            }
        }
    }

    .mobile-slidebar-menu {
        list-style-type: none;
        padding: 0;

        & > li {
            margin: 0;
            box-shadow: none;
			border: 0;

            & > a {
                display: block;
                padding: 12px 20px;
                font-size: 18px;
                font-weight: 400;
                color: #fff;
				background: darken($bg-slidebar, 10%);
                &:after {
                    font-family: 'Material-Design-Iconic-Font';
                    font-size: 1.2em;
                    float: right;
                    content: '\f273';
                    transition: all ease 0.3s;
                }

                &.collapsed {
                    background-color: $bg-slidebar-hover;
                    color: #ffffff;

                    &:after {
                        content: '\f278';
                        transform: rotate(180deg);
                    }
                }

                &.link {
                    background-color: $bg-slidebar-hover;
                    color: #ffffff;

                    &:after {
                        content: "\f2fb";
                    }
                }

                &:hover {
                    background-color: darken($bg-slidebar, 10%);
                    color: #ffffff;
                }

                & > i {
                    margin-right: 15px;
                }
            }

            & > ul {
                list-style-type: none;
                padding: 0;


                & > li {
		            margin: 0;
		            box-shadow: none;
					border: 0;
            		& > ul {
						list-style-type: none;
                		padding: 0;
				        & > li {
				            margin: 0;
				            box-shadow: none;
							border: 0;
							& > a {
				                display: block;
				                padding: 12px 20px;
				                font-size: 14px;
				                font-weight: 400;
				                color: #ffffff;
								background: darken($bg-slidebar, 15%);
            					position: relative;


				                &:after {
				                    font-family: 'Material-Design-Iconic-Font';
				                    font-size: 1.2em;
				                    float: right;
				                    content: '\f273';
				                    transition: all ease 0.3s;
				                	position: absolute;
				                	right: 20px;
				                	top: 10px;
				                }

				                &.collapsed {
				                    background-color: $bg-slidebar-hover;
				                    color: #ffffff;

				                    &:after {
				                        content: '\f278';
				                        transform: rotate(180deg);
				                    }
				                }

				                &.link {
				                    background-color: darken($bg-slidebar, 15%);
				                    color: #ffffff;

				                    &:after {
				                        content: "\f2fb";
				                    }
				                }

				                &:hover {
				                    background-color: darken($bg-slidebar, 10%);
				                    color: #ffffff;
				                }

				                & > i {
				                    margin-right: 15px;
				                }
				            }
			            }
					}
                    & > a {
                        display: block;
                        padding: 10px 20px;
                        font-size:16px;
                        color: #ffffff;
                        background-color: darken($bg-slidebar, 5%);
                    	position: relative;

                        &:hover {
                            color: #ffffff;
                        }

                        & > i {
                            margin-right: 15px;
                        }

		                &:after {
		                    font-family: 'Material-Design-Iconic-Font';
		                    font-size: 1.2em;
		                    content: '\f273';
		                    transition: all ease 0.3s;
		                	float: right;
		                }

		                &.collapsed {
		                    background-color: darken($bg-slidebar, 10%);
		                    color: #ffffff;

		                    &:after {
		                        content: '\f278';
		                        transform: rotate(180deg);
		                    }
		                }
		                &.link {
		                    background-color: darken($bg-slidebar, 15%);
		                    color: #ffffff;

		                    &:after {
		                        content: "\f2fb";
		                    }
		                }
                    }

                    &:last-child {
                        border-bottom: darken($bg-slidebar, 10%);
                    }
                }
            }
        }
    }

    &.mobile-left {
        box-shadow: 2px 0px 3px 0px rgba(0,0,0,0.21);
    }

    &.mobile-right {
        box-shadow: -2px 0px 3px 0px rgba(0,0,0,0.21);
    }

    &:hover {
        &::-webkit-scrollbar {
            //display: absolute;
        }
    }
}

.header-menu-logo {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;
    font-size: 16px;
    background: rgba(0,0,0, 0.2);
    height: 70px;
    font-weight: 500;
    i {
        $icon-height: 35px;
        font-size: 30px;
        height: $icon-height;
        width: $icon-height;
        text-align: center;
        background: #fff;
        border-radius: 50%;
        color: #000;
        line-height: $icon-height;
    }
}
