//OVERRIDE Bootstrap
$primary:  #3c4b64 !default;
$font-family-sans-serif:      "Open Sans" !default;
// Body
$body-bg: #ebedef;
$grid-gutter-width: 30px;

$color-blue: $primary;
$color-black: #000;
$color-white: #fff;

$color-primary: $color-blue;
$color-warning: #000;

// Typography
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange:  #fd7e14;
$yellow: #fd7e14;
$green: #068120;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$enable-gradients: true;
$enable-transitions: true;

//Slidebars
$bg-slidebar: #3c4b64;
$bg-slidebar-dropdown: #3c4b64;
$bg-slidebar-hover: #3c4b64;
$sidebar-link-active-color: #989898;
$slidebar-with: 250px;
