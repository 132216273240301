/* input */
.fileuploader-theme-boxafter {
    padding-top: 0.1px;
}
.fileuploader-theme-boxafter .fileuploader-input {
    display: block;
    margin-top: 48px;
    padding: 28px 0;
    background: rgba(120, 155, 236, 0.06);
    border: 2px dashed #d5dee8;
    border-radius: 14px;
    text-align: center;
    color: #bbc2d4;
    transition: all 0.2s ease;
}
.fileuploader-theme-boxafter .fileuploader-input h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}
.fileuploader-theme-boxafter .fileuploader-input h3 a {
    color: #789bec;
    text-decoration: none;
    font-weight: 700;
}
.fileuploader-theme-boxafter .fileuploader-input-button {
    position: absolute !important;
    top: -24px !important;;
    right: 12px !important;;
    width: 48px !important;;
    height: 48px !important;;
    padding: 0 !important;;
    font-size: 22px !important;;
    font-weight: 400 !important;;
    line-height: 48px !important;;
    border-radius: 50% !important;;
    box-shadow: 0 4px 12px rgba(0,0,0,.15) !important;;
}
.fileuploader-theme-boxafter .fileuploader-input.fileuploader-dragging {
    transform: scale(1.03);
    background: rgba(120, 155, 236, 0.1);
}

/* list */
.fileuploader-theme-boxafter .fileuploader-items {
    margin-top: -1px;
}
.fileuploader-theme-boxafter .fileuploader-items .fileuploader-item .fileuploader-progressbar {
    flex: 0;
    bottom: -6px;
    margin-left: 52px;
    width: calc(100% - 52px);
    background: rgba(0,0,0,0.06);
}
