.btn-float {
    height: 50px;
    line-height: 40px;

	i {
	    font-size: 23px;
	    transition: all 0.5s;
	}

    span {
        position: relative;
        display: inline-block;
        margin-left: 5px;
        top: -3px;
    }

	&:hover i {
	    transform: rotate(360deg);
	}
}

.btn:not(.btn-link) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px, rgba(0, 0, 0, 0.12) 0px 2px 10px;
}

.btn-float:not(.m-btn) {
}

table {
	.btn-group {
		display: flex;
	    justify-content: flex-end;
	}
}

.btn {
    position: relative;

	.link-badge {
		font-size: 11px;
	    width: 15px;
	    height: 15px;
	    display: block;
	    background: #333;
	    border-radius: 50%;
	    position: absolute;
	    top: 3px;
	    right: 3px;
	}
}

$social-colors: (
    facebook:     #3b5998,
    twitter:      #00aced,
    google-plus:  #dd4b39,
    github:       #333333,
    linkedin:     #007bb6,
    youtube:      #bb0000,
    vimeo:        #aad450,
    tumblr:       #32506d,
    instagram:    #517fa4,
    flickr:       #ff0084,
    vk:           #45668e,
    stumbleupon:  #EB4823,
    yahoo:        #7B0099
) !default;

@each $media, $color in $social-colors {
    .btn-#{$media} {
        @include button-variant($color, darken($color, 10%), darken($color, 10%));
    }
    .btn-outline-#{$media} {
        @include button-outline-variant($color);
    }
}
