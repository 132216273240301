.form-errors {
    ul {
        margin: 0;
        h4 {
            margin: 0;
            font-size: 1rem;
            text-transform: initial;
        }
        li {
            display: none;
        }
    }
}

form {
    label {
        font-weight: 700;
    }
}
