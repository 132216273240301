.webinardownload-form {
    .fileuploader {
        padding: 0;
        margin: 0;
    }

    .card-body {
        padding-top: 0 !important;
    }
}

.swal2-actions {
    button {
        text-transform: initial !important;
    }
}
