// Fonts
// Variables
@import 'variables';

// Bootstrap
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

//fileupload
@import "../vendor/fileuploader-2.2/src/jquery.fileuploader";
@import "../vendor/fileuploader-2.2/examples/boxafter/css/jquery.fileuploader-theme-boxafter.css";

//Mixins
@import "mixins/fontface";

//Layout
@import "layout/header";
@import "layout/layout";
@import "layout/login";

//components
@import "components/alertify";
@import "layout/layout";
@import "layout/login";
@import "components/slidebars";
@import "layout/header";
@import "components/data-tables";
@import "components/sweetalert";
@import "components/buttons";
@import "components/cards";
@import "components/checkbox";
@import "components/form";
@import "components/webinardownloads";
@import "components/facebookpost";
@import "components/fonts";
@import "components/stats";

//tiny


.col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
