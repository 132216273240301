.stats-item {
	position: relative;
    overflow: hidden;
    padding: 30px 20px;
    margin-bottom: 30px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
	display: block;

	> .fa {
	    position: absolute;
	    bottom: -20%;
	    left: -5%;
	    font-size: 100px;
	    color: hsla(0,0%,100%,.15);
	}

	&-title {
		display: block;
	    margin: 0;
	    text-align: right;
	    font-size: 45px;
	    font-weight: 300;
	    color: #fff;
		line-height: 0.8;

		> small {
		    display: block;
		    font-size: 14px;
		    text-transform: capitalize;
		    color: hsla(0,0%,100%,.7);
			margin-top: 3px;
		}
	}

	&-red {
		background: #ef5350;
		&:hover {
			background: darken(#ef5350, 5%);
		}
	}

	&-blue {
		background: $color-blue;
		&:hover {
			background: darken($color-blue, 5%);
		}
	}

	&-green {
		background: $green;
		&:hover {
			background: darken($green, 5%);
		}
	}

	&:hover {
		transition: ease all 0.3s;
		box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
	}
}
