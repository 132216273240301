:root {
    --white: #fff;
    --background: #f0f2f5;
    --hover-shadow: #f2f2f2;
    --hover-shadow-2: #e4e6e9;
    --border: #ccc;
    --box-shadow: rgba(0, 0, 0, 0.2);
    --reactions-links: #65676b;
    --black1: #1c1e21;
    --black2: #050505;
    --green: #2f9a48;
}

.facebook-post-preview {
    font-size: 10px;

    .content__paragraph p {
        margin: 0;
    }
}

.post {
    background: var(--white);
    width: 100%;
    max-width: 50em;
    padding-bottom: 5em;
    border-radius: 1em;
    box-shadow: 0 0.1em 0.2em var(--box-shadow);
}

.post__header {
    display: flex;
    justify-content: space-between;
    padding: 1.2em 1.6em 0 1.6em;
}

/* Header left */
.header__left {
    flex: 1;
}

.author__name:hover,
.post__date:hover {
    text-decoration: underline;
}

.author__verified,
.post__privacy,
.options__icon {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    background-image: url(../images/icons-list-1.png);
    background-size: 7.1em 43.2em;
    background-repeat: no-repeat;
    background-position: -1.7em -38.6em;
}

.author__name {
    margin-right: 0.2em;
}

.post__author-pic {
    float: left;
    width: 4em;
    height: 4em;
    margin-right: 0.8em;
    border-radius: 50%;
    box-shadow: 0 0.1em 0.2em var(--box-shadow);
    transition: filter 0.2s ease;
}

.post__author-pic:hover {
    filter: brightness(0.95);
}

.post__author {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--black2);
}

.post__date {
    display: block;
    float: left;
    font-size: 1.3em;
    color: var(--black1);
}

/* Dot between post author and post date */
.post__date-privacy-separator {
    position: relative;
    bottom: 0.1em;
}

.post__privacy {
    background-image: url(../images/icons-list-2.png);
    background-size: 2.6em 89.4em;
    background-position: 0 -78.2em;
    opacity: 0.6;
}

/* Header right */
.post__options {
    padding: 1em;
    border-radius: 50%;
    line-height: 50%;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.post__options:hover {
    background: var(--hover-shadow);
}

.options__icon {
    height: 2em;
    width: 2em;
    position: relative;
    background-size: 7.4em 43em;
    background-position: -2.2em -24em;
    opacity: 0.6;
}

/****************
 * POST CONTENT *
 ****************/



.content__paragraph {
    font-size: 1.5em;
    padding: 0.8em 1.6em 0.8em 1.6em;

    a {
        color: #216FF3;
    }
}

.content__image {
    width: 100%;
    height: 33em;
    object-fit: cover;
    display: block;
}

.content__image:hover {
    cursor: pointer;
}

/***************
 * POST FOOTER *
 ***************/

/* REACTIONS */
.footer__reactions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8em 1.6em;
    color: var(--reactions-links);
    font-size: 1.5em;
}

.reactions__emojis {
    display: flex;
    align-items: center;
}

.emojis__like,
.emojis__haha,
.emojis__love {
    width: 1.8em;
    height: 1.8em;
    position: relative;
    cursor: pointer;
}

.emojis__like {
    z-index: 2;
}

.emojis__haha {
    z-index: 1;
    left: -0.2em;
}

.emojis__love {
    left: -0.4em;
}

.emojis__count {
    margin-left: 0.3em;
}

.comment-shares__shares {
    margin-left: 0.2em;
}

.emojis__count:hover,
.comment-shares__comments:hover,
.comment-shares__shares:hover {
    text-decoration: underline;
}

/* BUTTONS (LIKE, COMMENT, SHARE) */
.footer__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 1.2em;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
}

.buttons__like,
.buttons__comment,
.buttons__share {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 3.5em;
    margin: 0.2em 0;
    font-size: 1.5em;
    font-weight: 600;
    border-radius: 0.5em;
    color: var(--reactions-links);
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.buttons__like:hover,
.buttons__comment:hover,
.buttons__share:hover {
    background: var(--hover-shadow);
}

.like__icon,
.comment__icon,
.share__icon {
    width: 1.8em;
    height: 1.8em;
    display: inline-block;
    background-image: url("../images/icons-list-3.png");
    background-size: 2.6em 77.4em;
    background-repeat: no-repeat;
    opacity: 0.6;
    margin-right: 0.5em;
}

.like__icon {
    background-position: 0 -18em;
}

.comment__icon {
    background-position: 0 -14em;
}

.share__icon {
    background-position: 0 -20em;
}

/* POST COMMENTS */
.footer__comments {
    padding: 0.8em 1.2em;
    font-size: 1.5em;
    font-weight: 600;
    text-align: right;
    color: var(--reactions-links);
}

/* Comments filter */
.comments__filter {
    display: inline-block;
    margin-bottom: 0.8em;
    cursor: pointer;
}

.filter__icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url("../images/icons-list-4.png");
    background-size: 7.4em 43.4em;
    background-repeat: no-repeat;
    background-position: -5.4em -36.4em;
    opacity: 0.6;
    vertical-align: middle;
}

/* Write a comment.. box */
.comments__box {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.box__profile {
    margin-right: 1em;
    position: relative;
}

.box__profile::after {
    content: "";
    display: block;
    position: absolute;
    width: 0.7em;
    height: 0.7em;
    bottom: 0.3em;
    left: 2.3em;
    background: var(--green);
    border: 0.3em solid var(--white);
    border-radius: 50%;
}

.box__profile:hover {
    cursor: pointer;
}

.profile__pic {
    width: 3.2em;
    height: 3.2em;
    border-radius: 50%;
}

.box__bar {
    display: flex;
    align-items: center;
    flex: 1;
}

.bar__input {
    width: 100%;
    background: var(--background);
    border: none;
    padding: 1.2em;
    font-size: 1.5em;
    color: rgba(5, 5, 5, 0.8);
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    outline: none;
    flex: 1;
}

.bar__input::placeholder {
    opacity: 0.8;
}

.bar__input:focus::-webkit-input-placeholder {
    opacity: 0.6;
}

.bar__emojis {
    display: flex;
    align-items: center;
    height: 4.16em;
    background: var(--background);
    padding-right: 1.2em;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
}

.emojis__insert,
.emojis__attach,
.emojis__gif,
.emojis__sticker {
    border-radius: 50%;
    padding: 0.5em;
    line-height: 0.5em;
    margin-left: 0.3em;
    cursor: pointer;
}

.emojis__insert:hover,
.emojis__attach:hover,
.emojis__gif:hover,
.emojis__sticker:hover {
    background: var(--hover-shadow-2);
}

.insert__emoji,
.attach__emoji,
.gif__emoji,
.sticker__emoji {
    width: 1.6em;
    height: 1.6em;
    display: inline-block;
    background-image: url("../images/icons-list-3.png");
    background-size: 2.6em 77.4em;
    background-repeat: no-repeat;
    opacity: 0.6;
}

.insert__emoji {
    background-position: 0 -32.8em;
}

.attach__emoji {
    background-position: 0 -27.4em;
}

.gif__emoji {
    background-position: 0 -34.6em;
}

.sticker__emoji {
    background-position: 0 -40em;
}

/* Friends comments */
.comments__friend-comment {
    display: flex;
    text-align: start;
}

.friend-comment__pic {
    width: 3.2em;
    height: 3.2em;
    border-radius: 50%;
    margin-right: 1em;
    cursor: pointer;
    transition: filter 0.2s ease;
}

.friend-comment__pic:hover {
    filter: brightness(0.95);
}

.friend-comment__comment {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 0.5em;
    padding: 0.8em 1.2em;
    background: var(--background);
    border-radius: 1.5em;
}

.comment__author {
    align-self: flex-start;
    color: var(--black2);
    font-size: 1.35em;
}

.comment__author:hover {
    text-decoration: underline;
}

.comment__content {
    font-size: 1.5em;
    font-weight: 400;
    color: var(--black2);
}

.comment__reactions {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -1.5em;
    right: 0;
    padding: 0.2em 0.5em;
    background: var(--white);
    box-shadow: 0 0.1em 0.2em var(--box-shadow);
    border-radius: 1em;
    cursor: pointer;
}

.reactions__emojis {
    width: 1.6em;
    height: 1.6em;
    position: relative;
}

.reactions__like {
    z-index: 2;
}

.reactions__haha {
    z-index: 1;
    left: -0.3em;
}

.reactions__love {
    left: -0.6em;
}

.reactions__count {
    position: relative;
    right: 0.1em;
    font-size: 1.32em;
    font-weight: 400;
    color: var(--reactions-links);
}

.friend-comment__options {
    align-self: center;
    padding: 0.8em;
    border-radius: 50%;
    line-height: 0.8em;
    cursor: pointer;
}

.friend-comment__options:hover {
    background: var(--hover-shadow);
}

.options__comment {
    width: 1.6em;
    height: 1.6em;
    background-position: 0 -36.8em;
    background-size: 7.4em 44.6em;
    background-image: url("../images/icons-list-5.png");
}

.comment__links,
.comment__replies {
    position: absolute;
}

/* Like, reply and comment date */
.comment__links {
    bottom: -2em;
    color: var(--reactions-links);
    font-size: 1.2em;
    font-weight: 400;
}

.comment__replies {
    display: flex;
    align-items: center;
    bottom: -4.5em;
}

.comment__replies:hover {
    cursor: pointer;
    text-decoration: underline;
}

.links__like,
.links__reply {
    font-weight: 700;
}

.links__like:hover,
.links__reply:hover,
.links__date:hover {
    text-decoration: underline;
}

.replies__emoji {
    width: 1.6em;
    height: 1.6em;
    background-image: url("../images/icons-list-6.png");
    background-position: -5.4em -24.4em;
    background-size: 7.4em 32.2em;
    background-repeat: no-repeat;
    display: inline-block;
    opacity: 0.6;
    margin-right: 0.5em;
}

.comments__more-comments {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: -5em;
}

.more-comments__link:hover {
    text-decoration: underline;
}

.more-comments__count {
    font-weight: 400;
}
