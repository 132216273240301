.swal2-modal {
	border-radius: 2px;
    padding: 30px!important;
    box-shadow: 0 5px 20px rgba(0,0,0,.07);
	
	.swal2-icon {
	    margin-top: 0;
	    margin-bottom: 20px;
	}
	
	.swal2-title {
	    font-size: 16px;
	    position: relative;
	    z-index: 1;
	    color: #333;
	    line-height: inherit;
	    margin: 0 0 5px;
	    font-weight: 500;
	}	
	
	.swal2-content {
	    color: #777;
	    font-size: 13px;
	    font-weight: 400;
	}	
	
	.swal2-styled {
	    padding: 6px 12px;
	    font-size: 12px;
	    border-radius: 2px;
	    margin: 0 2px;
	    text-transform: uppercase;
	    font-weight: 400;
	    font-family: inherit;
	    -webkit-transition: all;
	    -o-transition: all;
	    transition: all;
	    -webkit-transition-duration: .3s;
	    transition-duration: .3s;
	    box-shadow: none !important;
		&:not(:hover) {
		    opacity: .85;
		    filter: alpha(opacity=85);
		}		
	}	
}