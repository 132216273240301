body {
    font-weight: 300;
}

.page-wrapper {
    margin-top: 110px;

    aside#sidebar {
        width: 300px;
    }

    section.content {
        padding-bottom: $grid-gutter-width;

        @media(min-width: 1200px) {
            padding-left: $slidebar-with + $grid-gutter-width;
        }
    }
}

[canvas=container] {
    transform: none;
    -webkit-transform: none;
}

html,body {
    height:100%;
    max-width: 1920px;
    margin: 0;
    overflow: visible;
}

main {
    padding-bottom: $grid-gutter-width*2;
}

a {
    color: $color-primary;
    transition: all 0.4s ease;

    &:hover, &:focus {
        text-decoration: none;
        color: lighten($color-primary, 10%);
    }
}

h1,h2,h3,h4,h5,h6 {
    color: $color-primary;
    font-weight: 700;

    @media(max-width:767px) {
        font-size: 10vw;
    }
}

.btn,
.page-link{
    box-shadow: none !important;
}


.btn::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 25%;
    height: 100%;
    width: 50%;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 0.5s;
    transform: scale(5, 5);
}

.btn:active::after {
    padding: 0;
    margin: 0;
    opacity: .2;
    transition: 0s;
    transform: scale(0, 0);
}

.service-container.bg-gradient-light {
    background: #ffffff linear-gradient(180deg, #ffffff, darken(#fff, 2%)) repeat-x !important
}

.service-container + .service-container {
    margin-top: $grid-gutter-width;
}

.fixed-save-button-container {
    left: $slidebar-with ;
    width: calc(100% - #{$slidebar-with});
    box-shadow: 0 -0.5rem 1rem rgba(0,0,0,.15);
    background: #ffff;
    padding: 10px;
    text-align: center;
    display: none;

    .btn-float {
        line-height: initial;
        height: auto;
    }

    @media(max-width: 1199px) {
        left: 0;
        width: 100%;
    }
}

