dataTables_wrapper {
    position: relative;
}

.dataTables_filter, .dataTables_length {
    padding: 20px 30px;
}

.dataTables_filter label, .dataTables_length label {
    font-weight: 400;
}

.dataTables_length select {
    margin: 0 8px;
}

.dataTables_filter input, .dataTables_filter select, .dataTables_length input, .dataTables_length select {
    border: 1px solid #eee;
    height: 35px;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 100%;
    background-color: #fff;
}

.dataTables_wrapper .dataTables_filter {
    width: 40%;
}

.dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_filter label {
    width: 100%;
}
.dataTables_wrapper .dataTables_filter input {
    margin: 0!important;
}
.dataTables_wrapper .dataTables_filter input {
    margin-left: 0.5em;
}
.dataTables_filter input, .dataTables_filter select, .dataTables_length input, .dataTables_length select {
    border: 1px solid #eee;
    height: 35px;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 100%;
    background-color: #fff;
}

.dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_filter label {
    width: 100%;
}
.dataTables_filter label {
    position: relative;
    font-size: 0;
}

.dataTables_filter label:after {
    content: '\f1c3';
    font-family: Material-Design-Iconic-Font;
    position: absolute;
    font-size: 18px;
    right: 15px;
    top: 5px;
}

.table>thead>tr>th {
    vertical-align: middle;
    font-weight: 500;
    color: #333;
    border-width: 1px;
    text-transform: uppercase;
	border-bottom: 1px solid #F5F5F5;
}
.table>tbody>tr>td {
    vertical-align: middle;
}

.table {
	.btn-group  .btn {
	    margin: 0 5px 0 0;
	}
}

.dataTables_info, .dataTables_paginate {
    padding: 30px;
}

.dataTables_wrapper .dataTables_paginate .mdl-button.next, .dataTables_wrapper .dataTables_paginate .mdl-button.previous {
    font-size: 0;

	&.previous {
		&:after {
			content: '\f2ff';
		    font-family: Material-Design-Iconic-Font;
		    font-size: 18px;
			position: relative;
			top:5px;
		}
	}

	&.next {
		&:after {
			content: '\f301';
		    font-family: Material-Design-Iconic-Font;
		    font-size: 18px;
			position: relative;
			top:5px;
		}
	}
}

.dataTables_wrapper .dataTables_paginate .mdl-button.current, .dataTables_wrapper .dataTables_paginate .mdl-button.current:focus, .dataTables_wrapper .dataTables_paginate .mdl-button.current:hover {
    background: $color-blue;
    color: #fff!important;
}

.dataTables_wrapper .dataTables_paginate .mdl-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $color-blue;
    vertical-align: top;
    color: #fff!important;
    margin: 0 2px;
    border: 0!important;
    line-height: 21px;
    box-shadow: none!important;
}

table.dataTable thead th, table.dataTable thead td {
	padding-left: 10px;
}

.dataTables_wrapper .dataTables_length {
	padding-left: 0;
}

.dataTables_wrapper .dataTables_filter {
	padding-right: 0;
}

.dataTables_filter input, .dataTables_filter select, .dataTables_length input, .dataTables_length select {
	margin-left: 0;
    min-width: 75px;
}

.dataTables_wrapper .dataTables_info {
	padding-top: 25px;
    padding-left: 0;
}

.dataTables_wrapper .dataTables_paginate {
	padding-top: 20px;
	padding-right: 0;

	.pagination {
		margin: 0;
	}
}
div.dataTables_wrapper div.dataTables_filter {
    text-align: right;
    float: right;
}

.dt-bootstrap4 {
    tbody {
        tr {
            > td {
                &:last-child {
                    width: 1px;
                    white-space: nowrap;
                }
            }
        }
    }
}

div.dataTables_wrapper div.dataTables_processing {
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0, 0.5);
    color: #fff;

    .inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
    }

    span {
        display: block;
        margin-top: 5px;
    }
}
.btn-secondary.buttons-csv,
.btn-secondary.buttons-excel {
    @include button-variant(#14a73c, #14a73c);
}

div.dataTables_wrapper div.dataTables_length label {
    justify-content: flex-start;
}
div.dataTables_wrapper div.dataTables_filter label {
    justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_paginate {
    justify-content: flex-end;
    display: flex;
    flex-flow: row;
    align-items: center;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 25px;
}
.dataTables_wrapper {
    display: block !important;
}
