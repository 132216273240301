body.login-screen {
    background: $color-blue;
}

.login {
    overflow: hidden;
    height: 100vh;
    text-align: center;
    -webkit-transition: background-color;
    -o-transition: background-color;
    transition: background-color;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    animation-duration: 1s;
    animation-delay: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l-block {
    background: #fff;
    border-radius: 2px;
    max-width: 350px;
    width: 100%;
    vertical-align: middle;
    position: relative;
    display: none;
    text-align: left;
    box-shadow: 3px 6px 57px -12px rgba(0,0,0,0.75);

}

.l-block.toggled {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index: 10;
    display: inline-block;
}

.lb-header {
    padding: 25px 30px;
    color: $color-black;
    border-radius: 1px 1px 0 0;
}

.lb-header i,
.lb-header .avatar-img {
    display: block;
    margin-bottom: 10px;
}

.lb-header .avatar-img {
    border: 2px solid #fff;
}

.lb-header i {
    font-size: 40px;
}

.lb-body {
    padding: 30px;
}

.lb-body a:hover {
    text-decoration: underline;
}

#profile-main {
    min-height: 500px;
    position: relative;
}

#profile-main .pm-overview {
    overflow-y: auto;
}

@media (min-width: 1200px) {
    #profile-main .pm-overview {
        width: 300px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    #profile-main .pm-overview {
        width: 250px;
    }
}

@media (min-width: 768px) {
    #profile-main .pm-overview {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #f8f8f8;
        border-right: 1px solid #eee;
    }
}

@media (max-width: 767px) {
    #profile-main .pm-overview {
        width: 100%;
        background: #333;
        text-align: center;
    }
}

@media (min-width: 1200px) {
    #profile-main .pm-body {
        padding-left: 300px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    #profile-main .pm-body {
        padding-left: 250px;
    }
}

@media (max-width: 767px) {
    #profile-main .pm-body {
        padding-left: 0;
    }
}

#profile-main .pmo-pic {
    position: relative;
    margin: 20px;
}

@media (min-width: 768px) {
    #profile-main .pmo-pic img {
        width: 100%;
        border-radius: 2px 2px 0 0;
    }
}

@media (max-width: 767px) {
    #profile-main .pmo-pic img {
        width: 180px;
        display: inline-block;
        height: 180px;
        border-radius: 50%;
        border: 4px solid #fff;
        box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    }
}

#profile-main .pmo-pic .pmo-stat {
    border-radius: 0 0 2px 2px;
    color: #fff;
    text-align: center;
    padding: 30px 5px 0;
}

@media (min-width: 768px) {
    #profile-main .pmo-pic .pmo-stat {
        background: #ffc107;
        padding-bottom: 15px;
    }
}

#profile-main .pmo-pic .pmop-edit {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.38);
    text-align: center;
    padding: 10px 10px 11px;
    -webkit-transition: opacity;
    -o-transition: opacity;
    transition: opacity;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
}

#profile-main .pmo-pic .pmop-edit:hover {
    background: rgba(0, 0, 0, 0.8);
}

#profile-main .pmo-pic .pmop-edit i {
    font-size: 18px;
    vertical-align: middle;
    margin-top: -3px;
}

@media (min-width: 768px) {
    #profile-main .pmo-pic .pmop-edit {
        width: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    #profile-main .pmo-pic .pmop-edit i {
        margin-right: 4px;
    }
}

#profile-main .pmo-pic:hover .pmop-edit {
    opacity: 1;
    filter: alpha(opacity=100);
}

#profile-main .pmo-pic .pmop-message {
    position: absolute;
    bottom: 27px;
    left: 50%;
    margin-left: -25px;
}

#profile-main .pmo-pic .pmop-message .dropdown-menu {
    padding: 5px 0 55px;
    left: -90px;
    width: 228px;
    height: 150px;
    top: -74px;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

#profile-main .pmo-pic .pmop-message .dropdown-menu textarea {
    width: 100%;
    height: 95px;
    border: 0;
    resize: none;
    padding: 10px 19px;
}

#profile-main .pmo-pic .pmop-message .dropdown-menu button {
    bottom: 5px;
    left: 88px;
}

#profile-main .pmb-block {
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    #profile-main .pmb-block {
        padding: 40px 42px 0;
    }
}

@media (max-width: 1199px) {
    #profile-main .pmb-block {
        padding: 30px 20px 0;
    }
}

#profile-main .pmb-block:last-child {
    margin-bottom: 50px;
}

#profile-main .pmb-block .pmbb-header {
    margin-bottom: 25px;
    position: relative;
}

#profile-main .pmb-block .pmbb-header .actions {
    position: absolute;
    top: -2px;
    right: 0;
}

#profile-main .pmb-block .pmbb-header h2 {
    margin: 0;
    font-weight: 100;
    font-size: 20px;
}

#profile-main .pmb-block .pmbb-edit {
    position: relative;
    z-index: 1;
    display: none;
}

#profile-main .pmb-block .pmbb-edit,
#profile-main .pmb-block .pmbb-view {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

#profile-main .pmb-block.toggled .pmbb-edit {
    display: block;
}

#profile-main .pmb-block.toggled .pmbb-view {
    display: none;
}

#profile-main .pmo-block {
    padding: 25px;
}

#profile-main .pmo-block > h2 {
    font-size: 16px;
    margin: 0 0 15px;
}

#profile-main .pmo-items .pmob-body {
    padding: 0 10px;
}

#profile-main .pmo-items a {
    display: block;
    padding: 4px;
}

#profile-main .pmo-items a img {
    width: 100%;
}
