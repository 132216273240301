$md-checkbox-margin: 2px 0;
$md-checkbox-checked-color: $color-blue;
$md-checkbox-border-color: rgba(0, 0, 0, 0.25);

$md-checkbox-size: 20px;
$md-checkbox-padding: 4px;

$md-checkmark-width: 1px;
$md-checkmark-color: #fff;

$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-checked-color: $color-blue;
$md-radio-border-color: rgba(0, 0, 0, 0.54);

.form-check {
  position: relative;
  margin: $md-checkbox-margin !important;
    padding-left: 0;
  label {
	padding-left: 30px;
    cursor: pointer;
    &:before, &:after {
      content: "";
      position: absolute;
      left:0;
      top: 0;
    }

    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: 1px solid $md-checkbox-border-color;
      border-radius: 2px;
      cursor: pointer;
      transition: background .3s;
    }

    &:after {
      // checkmark
    }
  }

  input[type="checkbox"]{
    outline: 0;
    margin-right: $md-checkbox-size - 10px;
    display: none;
    &:checked {
       + label:before{
            background: $md-checkbox-checked-color;
            border:none;
      }
      + label:after {
        $md-checkmark-size: $md-checkbox-size - 2*$md-checkbox-padding;
        transform: rotate(-45deg);
        top: ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
        left: $md-checkbox-padding;
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;

        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      }
    }
  }

    input[type="radio"] {
        display: none;
        &:checked + label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;
        }
        &:checked + label:after {
            transform: scale(1);
        }

        + label {
            display: inline-block;
            min-height: $md-radio-size;
            position: relative;
            padding: 0 ($md-radio-size + 10px);
            margin-bottom: 0;
            cursor: pointer;
            vertical-align: bottom;
            font-weight: 400;
            &:before, &:after {
                position: absolute;
                content: '';
                border-radius: 50%;
                transition: all .3s ease;
                transition-property: transform, border-color;
            }
            &:before {
                left: 0;
                top: 0;
                width: $md-radio-size;
                height: $md-radio-size;
                border: 2px solid $md-radio-border-color;
            }
            &:after {
                top: $md-radio-size / 2 - $md-radio-checked-size / 2;
                left: $md-radio-size / 2 - $md-radio-checked-size / 2;
                width:$md-radio-checked-size;
                height:$md-radio-checked-size;
                transform: scale(0);
                background:$md-radio-checked-color;
            }
        }
    }
}

@media(min-width: 1200px) {
	.form-horizontal {
		.md-checkbox {
		  &.md-multicheckbox {
			margin: 0 !important;
		  }
		  label {
			text-align: right;
			margin-top: 10px;
		    min-height: 22px;

		    &:before, &:after {
		      right: -35px;
				left: auto;
		    }
		  }

		  input[type="checkbox"] {
		    display: none;
		    &:checked {
		      + label:after {
		        right: -31px;
		      	left: auto;
		      }
		    }
		  }
		&.md-multicheckbox {
			label {
				padding-top: 0;
				text-align: left;
				&:before, &:after {
					right: auto;
					left: 0;
				}
			}
			input[type="checkbox"] {
			    display: none;
			    &:checked {
			      + label:after {
			        right: auto;
			      	left: 5px;
			      }
			    }
			  }
		}
		}
	}
}

.bootstrap-select > .btn-default::before {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    height: calc(100% - 2px);
    width: 30px;
    background-color: #FFF;
    background-position: right calc(100% - 7px);
    background-repeat: no-repeat;
    background-image: url(../img/select.png);
    pointer-events: none;
    z-index: 5;
}
